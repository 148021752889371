# ハッシュがついていたらその位置までスクロールしてURLを戻す
evalHash = ->
  if location.hash
    scrollTo(location.hash)
    history.replaceState(null, null, location.href.replace(/#.*$/,""))

scrollTo = (_target, _speed, _callback)->
  target = if _target instanceof jQuery then _target else $(_target)

  if target.prop("tagName") == 'BODY'
    position = 0
  else
    position = target.position().top
  document.screen_scroll_target.animate {scrollTop: position}, _speed || 500, 'easeOutQuart', _callback

mapInit = ->
  latlng = new (google.maps.LatLng)(35.706325,139.782039)
  options =
    zoom: 17
    center: new (google.maps.LatLng)(35.706325,139.782039)
    mapTypeId: google.maps.MapTypeId.ROADMAP
    disableDefaultUI: true
    type: false
    zoomControl: true
    scrollwheel: false
  style = [
    {
      featureType: 'transit'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'administrative'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'poi'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'water'
      elementType: 'labels'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'water'
      stylers: [ {"color": "#e9e9e9"},{"lightness": 100}]
    }
    {
      featureType: 'road'
      elementType: 'labels'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'road'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'landscape'
      stylers: [
        { saturation: -100 }
        { lightness: 50 }
      ]
    }
  ]
  # generate map
  map = new (google.maps.Map)(document.getElementById('map'), options)
  markerOptions =
    position: latlng
    map: map
    title: 'i.lab'
  marker = new (google.maps.Marker)(markerOptions)
  styledMapType = new (google.maps.StyledMapType)(style, options)
  map.mapTypes.set 'noText', styledMapType
  map.setMapTypeId 'noText'

# 各記事のテキスト部分の高さ調整
# タイトル部分の高さに応じて本文の高さを調整
adjustTextHeight = ($target)->
  $target.each ->
    $me = $(this)
    $body = $me.find('.body')
    title_height = $me.find('.title').outerHeight()

    if(216 <= title_height) # 6行
      $me.addClass('title_6l')
    else if(180 <= title_height) # 5行
      $me.addClass('title_5l')
    else if(144 <= title_height) # 4行
      $me.addClass('title_4l')
    else if(114 <= title_height) # 3行（これ以上はcssのmin-heightで高さが変わらないはず）
      $me.addClass('title_3l')

    $me.attr('data-adjusted', true)

next_page_content = null
next_link_url = null
checkNextPage = ->
  if($('#readmore>a').length)
    $.ajax
        url: $('#readmore>a').attr('href')
        type: 'GET'
        dataType: 'html'
      .done (data)->
        posts = $(data).filter('#posts')
        if(0<posts.find('.post').length)
          # 次のページに記事がある
          next_page_content = posts.html()
          next_link_url = $(data).filter('#readmore').find('a').attr('href')
          $('#readmore>a').removeAttr('disabled')
        else
          # 次のページがない
          $('#readmore').remove()
      .fail (data)->
        console.error(data)
$ ->

  ua = navigator.userAgent
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)

    # スマホ

  else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0)

    # TODO 正規表現でwidthだけ変える
    # content = $('head>meta[name="viewport"]').attr('content')
    # new_content = content.replace(/width=\d+^\d/, 'width=1024')
    # console.log new_content
    $('head>meta[name="viewport"]').attr('content', 'width=1150')

  adjustTextHeight($('#posts>.post'))

  checkNextPage()

  document.screen_scroll_target = $('html,body')
  evalHash()

  mapInit() if $('body').is('.contact')

  $('#about').height $(document).outerHeight()

  $(document)
    .on 'click', 'a[href*=#]', (e)->
      e.preventDefault()
      id = "#"+$(this).attr('href').split('#')[1]
      scrollTo id

    # .on 'click', 'header nav.left', (e)->
    #   t = $(e.target)
    #   if t.is('nav.left') or t.is('#close_btn')or 0<t.parent('#close_btn').length
    #     # close_btnか、overlay部分がクリックされたらナビゲーションを消す
    #     # close_btn>iがtargetの場合も加味すること
    #     $('header nav').removeClass 'visible'

    # About ボタン
    .on 'click', '#about_btn', ->
      $('#about').fadeIn 250
    .on 'click', '#about, #about_close_btn', (e)->
      # 背景部分をクリックしていたら閉じる
      return if $(e.target).is('article') or $(e.target).parents('article').length
      # if($(e.target).is('#about') ||$(e.target).is('#about_close_btn') )
      me = $('#about_close_btn').hide()
      $('#about').fadeOut 250, -> me.show()

    # Menu ボタン
    .on 'click', '#menu_btn', ->
      $('#categories').addClass 'visible'
    .on 'click', '#menu_close_btn, #categories', (e)->
      t = $(e.target)
      if t.is('#categories') or t.is('#menu_close_btn')or 0<t.parent('#menu_close_btn').length
        # close_btnか、overlay部分がクリックされたらナビゲーションを消す
        # close_btn>iがtargetの場合も加味すること
        $('#categories').removeClass 'visible'

    # Read moreボタン
    .on 'click', '#readmore>a:not([disabled])', (e)->
      e.preventDefault()
      me = $(this)
      me.attr('disabled','disabled')
      # 記事追加
      $('#posts').append(next_page_content)
      adjustTextHeight($('#posts>.post:not([data-adjusted])'))
      me.attr('href', next_link_url)
      # 次のページがあるかチェック
      checkNextPage()