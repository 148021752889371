(function() {
  var adjustTextHeight, checkNextPage, evalHash, mapInit, next_link_url, next_page_content, scrollTo;

  evalHash = function() {
    if (location.hash) {
      scrollTo(location.hash);
      return history.replaceState(null, null, location.href.replace(/#.*$/, ""));
    }
  };

  scrollTo = function(_target, _speed, _callback) {
    var position, target;
    target = _target instanceof jQuery ? _target : $(_target);
    if (target.prop("tagName") === 'BODY') {
      position = 0;
    } else {
      position = target.position().top;
    }
    return document.screen_scroll_target.animate({
      scrollTop: position
    }, _speed || 500, 'easeOutQuart', _callback);
  };

  mapInit = function() {
    var latlng, map, marker, markerOptions, options, style, styledMapType;
    latlng = new google.maps.LatLng(35.706325, 139.782039);
    options = {
      zoom: 17,
      center: new google.maps.LatLng(35.706325, 139.782039),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      type: false,
      zoomControl: true,
      scrollwheel: false
    };
    style = [
      {
        featureType: 'transit',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'administrative',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'poi',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'water',
        stylers: [
          {
            "color": "#e9e9e9"
          }, {
            "lightness": 100
          }
        ]
      }, {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'road',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'landscape',
        stylers: [
          {
            saturation: -100
          }, {
            lightness: 50
          }
        ]
      }
    ];
    map = new google.maps.Map(document.getElementById('map'), options);
    markerOptions = {
      position: latlng,
      map: map,
      title: 'i.lab'
    };
    marker = new google.maps.Marker(markerOptions);
    styledMapType = new google.maps.StyledMapType(style, options);
    map.mapTypes.set('noText', styledMapType);
    return map.setMapTypeId('noText');
  };

  adjustTextHeight = function($target) {
    return $target.each(function() {
      var $body, $me, title_height;
      $me = $(this);
      $body = $me.find('.body');
      title_height = $me.find('.title').outerHeight();
      if (216 <= title_height) {
        $me.addClass('title_6l');
      } else if (180 <= title_height) {
        $me.addClass('title_5l');
      } else if (144 <= title_height) {
        $me.addClass('title_4l');
      } else if (114 <= title_height) {
        $me.addClass('title_3l');
      }
      return $me.attr('data-adjusted', true);
    });
  };

  next_page_content = null;

  next_link_url = null;

  checkNextPage = function() {
    if (($('#readmore>a').length)) {
      return $.ajax({
        url: $('#readmore>a').attr('href'),
        type: 'GET',
        dataType: 'html'
      }).done(function(data) {
        var posts;
        posts = $(data).filter('#posts');
        if (0 < posts.find('.post').length) {
          next_page_content = posts.html();
          next_link_url = $(data).filter('#readmore').find('a').attr('href');
          return $('#readmore>a').removeAttr('disabled');
        } else {
          return $('#readmore').remove();
        }
      }).fail(function(data) {
        return console.error(data);
      });
    }
  };

  $(function() {
    var ua;
    ua = navigator.userAgent;
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {

    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
      $('head>meta[name="viewport"]').attr('content', 'width=1150');
    }
    adjustTextHeight($('#posts>.post'));
    checkNextPage();
    document.screen_scroll_target = $('html,body');
    evalHash();
    if ($('body').is('.contact')) {
      mapInit();
    }
    $('#about').height($(document).outerHeight());
    return $(document).on('click', 'a[href*=#]', function(e) {
      var id;
      e.preventDefault();
      id = "#" + $(this).attr('href').split('#')[1];
      return scrollTo(id);
    }).on('click', '#about_btn', function() {
      return $('#about').fadeIn(250);
    }).on('click', '#about, #about_close_btn', function(e) {
      var me;
      if ($(e.target).is('article') || $(e.target).parents('article').length) {
        return;
      }
      me = $('#about_close_btn').hide();
      return $('#about').fadeOut(250, function() {
        return me.show();
      });
    }).on('click', '#menu_btn', function() {
      return $('#categories').addClass('visible');
    }).on('click', '#menu_close_btn, #categories', function(e) {
      var t;
      t = $(e.target);
      if (t.is('#categories') || t.is('#menu_close_btn') || 0 < t.parent('#menu_close_btn').length) {
        return $('#categories').removeClass('visible');
      }
    }).on('click', '#readmore>a:not([disabled])', function(e) {
      var me;
      e.preventDefault();
      me = $(this);
      me.attr('disabled', 'disabled');
      $('#posts').append(next_page_content);
      adjustTextHeight($('#posts>.post:not([data-adjusted])'));
      me.attr('href', next_link_url);
      return checkNextPage();
    });
  });

}).call(this);
